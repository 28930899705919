import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import VueCookies from "vue-cookies";
import boardStore from "@/store/modules/boardStore.js";
import branchStore from "@/store/modules/branchStore.js";
import commonStore from "@/store/modules/commonStore.js";
import contLogStore from "@/store/modules/contLogStore.js";
import mainStore from "@/store/modules/mainStore.js";
import mediaStore from "@/store/modules/mediaStore.js";
import menuStore from "@/store/modules/menu.js";
import mypageStore from "@/store/modules/mypageStore.js";
import onlineStore from "@/store/modules/onlineStore.js";
import quizStore from "@/store/modules/quizStore.js";
import ratingStore from "@/store/modules/ratingStore.js";
import userStore from "@/store/modules/userStore.js";
import statsStore from "@/store/modules/statsStore";

export default new createStore({
  state: {
    layout: "web",
    layout_style: "full",
    user: {
      loggedIn: false,
      data: null,
    },
  },
  mutations: {
    setLayout(state, payload) {
      state.layout = payload;
    },
    setLoggedIn(state, value) {
      state.user.loggedIn = value;
    },
    setUser(state, data) {
      state.user.data = data;
      VueCookies.set("magicbody_accessToken", data.accessToken);
      VueCookies.set("magicbody_refreshToken", data.refreshToken);
      VueCookies.set("magicbody_email", data.email);
      VueCookies.set("magicbody_useruid", data.useruid);
      VueCookies.set("magicbody_username", data.username);
      VueCookies.set("magicbody_sessionToken", data.sessionToken);
    },
    logoutUser(state) {
      state.user.loggedIn = false;
      state.user.data = null;
      VueCookies.remove("magicbody_accessToken");
      VueCookies.remove("magicbody_refreshToken");
      VueCookies.remove("magicbody_email");
      VueCookies.remove("magicbody_useruid");
      VueCookies.remove("magicbody_username");
      VueCookies.remove("magicbody_sessionToken");
    },
    resetUserInfo(state, data) {
      if (state.user.data == null) {
        state.user.data = data;
        VueCookies.set("magicbody_accessToken", data.accessToken);
        VueCookies.set("magicbody_refreshToken", data.refreshToken);
        VueCookies.set("magicbody_email", data.email);
        VueCookies.set("magicbody_useruid", data.useruid);
        VueCookies.set("magicbody_username", data.username);
        VueCookies.set("magicbody_sessionToken", data.sessionToken);
      }
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
    user(state) {
      return state.user;
    },
  },
  actions: {},
  modules: {
    boardStore: boardStore,
    branchStore: branchStore,
    commonStore: commonStore,
    contLogStore: contLogStore,
    mainStore: mainStore,
    mediaStore: mediaStore,
    menuStore: menuStore,
    mypageStore: mypageStore,
    onlineStore: onlineStore,
    quizStore: quizStore,
    ratingStore: ratingStore,
    userStore: userStore,
    statsStore: statsStore,
  },
  plugins: [
    createPersistedState({
      paths: ["user"],
    }),
  ],
});
